import React from "react";

import { Content } from "antd/lib/layout/layout";

import { LayoutGeneral } from "src/layout/LayoutGeneral";
import MembranaHowComponent from "src/components/membrana/how/Component";

const ComoFunciona = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      pageTitleJSX: (
        <span>
          <span className="mainTitle"> Como la piel humana: </span>
          <span className="secondaryTitle">
            la Membrana Termocerámica de ClimateCoating tiene propiedades únicas
          </span>
        </span>
      ),
      pageTitle:
        "Como la piel humana: la Membrana Termocerámica de ClimateCoating tiene propiedades únicas",
      pageName: "Membrana de ClimateCoating: ¿Cómo funciona?",
      pageDescription:
        "La membrana termocerámica de ClimateCoating tien un comportamiento muy similar al de la piel humana.",
    }}
  >
    <Content className="page">
      <MembranaHowComponent />
    </Content>
  </LayoutGeneral>
);

export default ComoFunciona;
