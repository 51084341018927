import React from "react";

import { List, Divider } from "antd";

import naveEspacialImg from "src/components/membrana/how/images/climateCoating-alta-resistencia-agentes-externos.png";
import burbujasCeramicasImg from "src/components/membrana/how/images/climateCoating-burbujas-ceramicas.png";

export const Resistente = (props) => {
  return (
    <div className="standardContent ">
      <div className="contentBlock marginAuto">
        <List
          {...{
            className: "listContentBlockWrapper",
            grid: {
              gutter: 40,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            },
            dataSource: [
              {
                imgClassName: "",
                imgSrc: naveEspacialImg,
                imageLegend:
                  "Los materiales cerámicos son capaces de resistir elevadas temperaturas",
                content: (
                  <p>
                    <strong>
                      ClimateCoating se sirve de la tecnología de membrana
                      cerámica utilizada en la industria espacial
                    </strong>
                    <br />
                    <br />
                    Los materiales cerámicos se utilizan como material
                    preferente de aislamiento en las naves que atraviesan la
                    atmósfera.
                    <br />
                  </p>
                ),
              },
              {
                imgClassName: "",
                imgSrc: burbujasCeramicasImg,
                imageLegend: "Está compuesto de microesferas de varios tamaños",
                content: (
                  <p>
                    <strong>
                      Su estructura de microesferas le confiere maxima robustez{" "}
                    </strong>
                    <br />
                    <br />
                    Las microesferas que están unidas entre sí mediante un
                    ligante y aditivos, forman una estructura estable y
                    resitente.{" "}
                  </p>
                ),
              },
            ],
            renderItem: (item) => (
              <List.Item className="listContentBlock">
                <img
                  className={"blockImage " + item.imgClassName}
                  src={item.imgSrc}
                />
                <span className="contentBlockImgLegend">
                  {item.imageLegend}
                </span>
                <Divider className="dividerHorizontalCC" />
                <>{item.content}</>
              </List.Item>
            ),
          }}
        />
      </div>
      <div className="standardContentBottomTitle">
        La Membrana de ClimateCoating refleja la mayoría de la radiación solar
        recibida
      </div>
    </div>
  );
};

export default Resistente;
