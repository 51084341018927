import React from "react";
import { List, Divider } from "antd";
import permeabilidadImg from "src/components/membrana/how/images/climatecoating-permeabilidad-variable.jpg";
import permeabilidadImgMobile from "src/components/membrana/how/images/climateCoating-permeabilidad-variable-mobile.jpg";

export const PermeabilidadVariable = (props) => {
  return (
    <div className="standardContent">
      <div className="contentBlock marginAuto">
        <img src={permeabilidadImg} className="imageAtTop ccDesktopView" />
        <img src={permeabilidadImgMobile} className="imageAtTop ccMobileView" />

        <List
          {...{
            className: "listContentBlockWrapper",
            grid: {
              gutter: 20,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            },
            dataSource: [
              {
                title: "IMPERMEABLE AL AGUA",
                content: (
                  <p>
                    <strong>
                      En presencia de agua la resina polimérica se hincha
                      volviéndose estanca
                    </strong>
                    <br />
                    <br />
                    La resina polimérica llega a aumentar un 25 % su grosor y se
                    vuelve completamente impermeable al paso del agua en estado
                    líquido
                  </p>
                ),
              },
              {
                title: "PERMEABLE AL VAPOR DE AGUA",
                content: (
                  <p>
                    <strong>
                      Cuando las condiciones son secas los poros se abren y la
                      humedad puede salir.
                    </strong>
                    <br />
                    <br />
                    La humedad puede por lo tanto atravesar la membrana
                    permitiendo respirar a los edificios extrayendo la humedad
                    almacenada en la envolvente
                  </p>
                ),
              },
            ],
            renderItem: (item) => (
              <List.Item className="listContentBlock">
                <h3>{item.title}</h3>
                {item.content}
              </List.Item>
            ),
          }}
        />
      </div>
      <div className="standardContentBottomTitle">
        La Membrana de ClimateCoating replica el comportamiento de la piel
      </div>
    </div>
  );
};

export default PermeabilidadVariable;
