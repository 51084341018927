import React from "react";
import { Tabs } from "antd";
import {
  RadarChartOutlined,
  FullscreenOutlined,
  ScanOutlined,
  VerticalAlignMiddleOutlined,
  ToTopOutlined,
  SketchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ComposicionQuimica from "src/components/membrana/how/ComposicionQuimica";
import ReflectividadEmisividad from "src/components/membrana/how/ReflectividadEmisividad";
import PermeabilidadVariable from "src/components/membrana/how/PermeabilidadVariable";
import Higroscopica from "src/components/membrana/how/Higroscopica";
import Flexibilidad from "src/components/membrana/how/Flexibilidad";
import Resistente from "src/components/membrana/how/Resistente";

const { TabPane } = Tabs;
export const MembranaComponent = (props) => (
  <>
    <div className="membranaContentTabs">
      <Tabs defaultActiveKey="info" centered={true}>
        <TabPane
          tab={
            <span>
              <InfoCircleOutlined /> info
            </span>
          }
          key="info"
        >
          <div
            style={{
              width: "90%",
              maxWidth: "1000px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "75px",
              marginBottom: "50px",
            }}
          >
            <video width="100%" controls>
              <source
                src="/videos/ClimateCoating-Reflective-Membrane-Technology.mp4"
                type="video/mp4"
              />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </TabPane>

        <TabPane
          tab={
            <span>
              <ScanOutlined /> Permeabilidad variable
            </span>
          }
          key="permeabilidad"
        >
          <PermeabilidadVariable />
        </TabPane>
        <TabPane
          tab={
            <span>
              <RadarChartOutlined />
              Composición Óptima
            </span>
          }
          key="quimica"
        >
          <ComposicionQuimica />
        </TabPane>
        <TabPane
          tab={
            <span>
              <ToTopOutlined />
              Reflectividad/Emisividad
            </span>
          }
          key="reflectividadEmisividad"
        >
          <ReflectividadEmisividad />
        </TabPane>
        <TabPane
          tab={
            <span>
              <VerticalAlignMiddleOutlined />
              Higroscópica
            </span>
          }
          key="higroscopica"
        >
          <Higroscopica />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FullscreenOutlined />
              Flexible
            </span>
          }
          key="flexibilidad"
        >
          <Flexibilidad />
        </TabPane>
        <TabPane
          tab={
            <span>
              <SketchOutlined />
              Resistente/Duradero
            </span>
          }
          key="resistente"
        >
          <Resistente />
        </TabPane>
      </Tabs>
    </div>
  </>
);

export default MembranaComponent;
