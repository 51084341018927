import React from "react";
import { List, Divider } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import flexibilidadMembranaOPintura from "src/components/membrana/how/images/climatecoating-flexibilidad-membrana-o-pintura.png";
import flexibilidadToldosCarpas from "src/components/membrana/how/images/climatecoating-flexibilidad-toldos-carpas.png";

export const Flexibilidad = (props) => {
  return (
    <div className="standardContent">
      <div className="contentBlock marginAuto">
        <div className="blockImgLeft">
          <div className="blockImg">
            <img
              className="applicationsLeftImg"
              src={flexibilidadMembranaOPintura}
            />
            <span className="legend">
              Comparativa de una pintura estandar versus la membrana de
              ClimateCoating
            </span>
          </div>
          <span className="blockText marginTop10">
            <br />
            <h2>
              LA FLEXIBILIDAD Y ELASTICIDAD ES CLAVE PARA PROTEGER EL EDIFICIO
            </h2>
            <br />
            <p>
              Las superficies de las edificaciones tienen, en función del
              material y del proceso constructivo, cierta rugosidad. Los agentes
              erosivos principalemnte el agua, aprovechan cualquier hoquedad por
              pequeña que sea para penetrar en el interior de los muros y
              cubiertas/tejados.
            </p>
            <br />
            <p>
              Sólo contando con un material que sea capaz de cubrir de manera
              efectiva la integridad de la superficie, independientemente del
              relieve permite que se pueda lograr una protección total.
            </p>
            <br />
            <p>
              Al ser impermeable y adaptarse a cualquier relieve, ClimateCoating
              gracias a su tecnología de membrana{" "}
              <strong>
                protege de manera efectiva la edificación frente a los agentes
                externos
              </strong>
              .
            </p>
            <p>
              Esto se traduce en:
              <ul className="productFeatureList">
                <li>
                  <CheckOutlined /> un ahorro en los costes de mantemiento
                </li>
                <li>
                  <CheckOutlined /> en mayor vida útil de la construcción
                </li>
                <li>
                  <CheckOutlined /> y en una mejora estética.
                </li>
              </ul>
            </p>
          </span>
        </div>
        <div className="clearBoth" />
        <div className="blockImgLeft">
          <div className="blockImg">
            <img
              className="applicationsLeftImg"
              src={flexibilidadToldosCarpas}
            />
            <span className="legend">
              ClimateCoating se ha aplica con éxito en toldos y carpas sin
              problemas de fisuras
            </span>
          </div>
          <span className="blockText ">
            <h2>FLEXIBILIDAD CONTRASTADA </h2>
            <p>
              Cuando se habla de que ClimateCoating tiene en la flexibilidad y
              elasticidad una de las principales razones de su excepcional
              rendimiento, se hace porque{" "}
              <strong>
                {" "}
                su capacidad de adherencia permite su aplicación en la practica
                totalidad de supercies
              </strong>
              .
            </p>
            <p>
              Sus propiedades elásticas quedan patentes cuando se aplica en
              toldos, carpas y lonas. No sólo se adhiere a sus superficies sino
              que se adapta y mueve con ellas sin generar fisuras de ningún
              tipo.
            </p>
            <br />
            <br />
            <br />
          </span>
        </div>
        <div className="clearBoth" />
      </div>
      <div className="standardContentBottomTitle">
        Al igual que la piel La Membrana de ClimateCoating cubre y envuelve la
        pared o cubierta adaptándose completamente
      </div>
    </div>
  );
};

export default Flexibilidad;
