import React from "react";
import microesferasCeramicasImg from "src/components/membrana/how/images/climatecoating-microesferas-ceramicas.jpg";
import dioxidoDeTitanioImg from "src/components/membrana/how/images/climatecoating-dioxido-de-titanio.png";
import liganteSuperAdhesivoImg from "src/components/membrana/how/images/climatecoating-ligante-superadhesivo.png";

import "src/components/membrana/how/style.scss";

const ComposicionQuimica = (props) => {
  return (
    <div className="standardContent contentBlock marginAuto">
      <div className="blockImgLeft">
        <img className="applicationsLeftImg" src={microesferasCeramicasImg} />
        <span className="blockText marginTop10">
          <h2>MICROESFERAS CERÁMICAS</h2>
          <p>
            Millones de “burbujas” de diámetro entre 1 décima y 1 centésima de
            milímetro Sus propiedades de dispersión y reflexión de la radiación
            permiten la distribución del calor en el interior de la membrana. Su
            estructura hueca unido a la composición cerámica hace que la
            membrana se comporte como una barrera térmica extraordinariamente
            eficaz. Evita la carga electrostática y la formación de compuestos
            químicos con partículas de suciedad.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg" src={dioxidoDeTitanioImg} />
          <span className="legend">
            Estructura cristalina de una de las formas de Dióxido de Titanio
          </span>
        </div>
        <span className="blockText ">
          <h2>DIÓXIDO DE TITANIO COMO ADITIVO JUNTO A PIGMENTOS</h2>
          <p>
            El Dióxido de titanio no es ni tóxico ni reactivo Como
            fotocatalizador permite descomponer los contaminantes
            medioambientales. No contiene ningún material orgánico semivolátil
            como plastificantes, disolventes orgánicos o isotiazolinonas.
            Ninguna partícula perjudicial para salud o alérgena es liberada tras
            su aplicación
          </p>{" "}
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img
            className="applicationsLeftImg marginTop10"
            src={liganteSuperAdhesivoImg}
          />
          <span className="legend">Ejemplo de estructura con 5 ligandos</span>
        </div>

        <span className="blockText">
          <h2>LIGANTE ESPECIAL SUPERADHESIVO EN BASE ACUOSA</h2>
          <p>
            {" "}
            Para garantizar la cohesión y flexibilidad de la membrana Tiene
            capacidad de variar su volumen (de hincharse) sin desprenderse de la
            base en la que es aplicado, permitiendo de esta manera la absorción
            del vapor de agua (varía su volumen hasta un 25 %). Tiene una
            densidad variable que impide el paso de moléculas de agua
            convirtiendo la membrana en impermeable al agua. Altamente
            compatible con pigmentos cromáticos ofreciendo más de 4000 colores
            disponibles.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
    </div>
  );
};

export default ComposicionQuimica;
