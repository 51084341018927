import React from "react";

import { List, Divider } from "antd";

import emisividadImg from "src/components/membrana/how/images/climatecoating-emisividad.png";
import reflectividadImg from "src/components/membrana/how/images/climatecoating-reflectividad.png";

export const ReflectividadEmisividad = (props) => {
  return (
    <div className="standardContent">
      <div className="contentBlock marginAuto">
        <List
          {...{
            className: "listContentBlockWrapper",
            grid: {
              gutter: 40,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            },
            dataSource: [
              {
                imgClassName: "",
                imgSrc: reflectividadImg,
                imageLegend:
                  "Refleja el calor solar tecnología de Membrana Reflectante de ClimateCoating",
                content: (
                  <p>
                    <strong>
                      ClimateCoating refleja el 86% del espectro visible e
                      infrarrojo cercano*.
                    </strong>
                    <br />
                    <br />
                    Refleja directamente el 86% de la radiación solar recibida
                    “quedando un 14% restante por emitir o transmitir”.
                    <br />
                    <span className="asterisc">
                      <br />* Valores medidos en ensayos oficiales con
                      ClimateCoating ThermoActive
                    </span>
                  </p>
                ),
              },
              {
                imgClassName: "",
                imgSrc: emisividadImg,
                imageLegend:
                  "ClimateCoating disipa Calor emitiendo energía por radiación",
                content: (
                  <p>
                    <strong>ClimateCoating tiene una emitancia del 90%</strong>
                    <br />
                    <br />
                    Del 14% de la radiación solar que no ha sido reflejada emite
                    un 90%, esto implica que sólo el 2% de la energía solar se
                    transmite a la estructura del edificio
                  </p>
                ),
              },
            ],
            renderItem: (item) => (
              <List.Item className="listContentBlock">
                <img
                  className={"blockImage " + item.imgClassName}
                  src={item.imgSrc}
                />
                <span className="contentBlockImgLegend">
                  {item.imageLegend}
                </span>
                <Divider className="dividerHorizontalCC" />
                <>{item.content}</>
              </List.Item>
            ),
          }}
        />
      </div>

      <div className="standardContentBottomTitle">
        La Membrana de ClimateCoating refleja la mayoría de la radiación solar
        recibida
      </div>
    </div>
  );
};

export default ReflectividadEmisividad;
