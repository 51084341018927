import React from "react";
import { List, Divider } from "antd";
import higroscopicaImg from "src/components/membrana/how/images/climatecoating-membrana-higroscopica.jpg";
import higroscopicaImgMobile from "src/components/membrana/how/images/climatecoating-membrana-higroscopica-mobile.jpg";

export const Higroscopica = (props) => {
  return (
    <div className="standardContent ">
      <div className="marginAuto contentBlock">
        <img src={higroscopicaImg} className="imageAtTop ccDesktopView" />
        <img src={higroscopicaImgMobile} className="imageAtTop ccMobileView" />

        <List
          {...{
            className: "listContentBlockWrapper",
            grid: {
              gutter: 20,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            },
            dataSource: [
              {
                title: "ELEVADA ABSORCIÓN DE HUMEDAD              ",
                content: (
                  <p>
                    <strong>
                      ClimateCoating absorbe humedad de la envolvente del
                      edificio
                    </strong>
                    <br />
                    <br />
                    Sirviéndose de la capilaridad, ClimateCoating extrae la
                    humedad que hay en el muro o superficie revestida y permite
                    su salida al exterior.
                  </p>
                ),
              },
              {
                title: "ADSORCIÓN DE HUMEDAD DEL EXTERIOR",
                content: (
                  <p>
                    <strong>
                      ClimateCoating adsorbe humedad del ambiente sea exterior o
                      interior
                    </strong>
                    <br />
                    <br />
                    Gracias a su efecto de superficie ampliada, es posible
                    atraer en gran medida el vapor de agua a su superficie,
                    potenciándose el efecto endotérmico de la membrana
                  </p>
                ),
              },
            ],
            renderItem: (item) => (
              <List.Item className="listContentBlock">
                <h3>{item.title}</h3>
                {item.content}
              </List.Item>
            ),
          }}
        />
      </div>
      <div className="standardContentBottomTitle">
        La Membrana "seca" los muros y forma una película húmeda en contacto con
        el exterior
      </div>
    </div>
  );
};

export default Higroscopica;
